export const technoLogos = [
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1656512517/Cryptr%20www/Landing%20pages/Techno%20logos/rails_logo_illustration_wpyb0r.svg',
    alt: 'Rails',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1656518064/Cryptr%20www/Landing%20pages/Techno%20logos/spring_security_logo_illustration_uisehm.svg',
    alt: 'Spring security',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1656512518/Cryptr%20www/Landing%20pages/Techno%20logos/vue_logo_illustration_qk3hna.svg',
    alt: 'Vue',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1656512515/Cryptr%20www/Landing%20pages/Techno%20logos/flask_logo_illustration_dhcrwl.svg',
    alt: 'Flask',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1656512515/Cryptr%20www/Landing%20pages/Techno%20logos/django_logo_illustration_tmk9d9.svg',
    alt: 'Django',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1656512515/Cryptr%20www/Landing%20pages/Techno%20logos/angular_logo_illustration_lku5mc.svg',
    alt: 'Angular',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1656512517/Cryptr%20www/Landing%20pages/Techno%20logos/react_logo_illustration_xmqspe.svg',
    alt: 'React',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1656512518/Cryptr%20www/Landing%20pages/Techno%20logos/php_symfony_logo_illustration_sqffte.svg',
    alt: 'Symfony',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1656512516/Cryptr%20www/Landing%20pages/Techno%20logos/laravel_logo_illustration_uqg59j.svg',
    alt: 'Laravel',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1656512515/Cryptr%20www/Landing%20pages/Techno%20logos/go_logo_illustration_zlixaf.svg',
    alt: 'Go',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1656512517/Cryptr%20www/Landing%20pages/Techno%20logos/react_native_logo_illustration_pskjlz.svg',
    alt: 'React Native',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1656512516/Cryptr%20www/Landing%20pages/Techno%20logos/ios_logo_ocg606.svg',
    alt: 'IOS',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1656512515/Cryptr%20www/Landing%20pages/Techno%20logos/android_logo_uoo13d.svg',
    alt: 'Android',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1656512515/Cryptr%20www/Landing%20pages/Techno%20logos/elixir_logo_ms2kf9.svg',
    alt: 'Elixir',
  },
]

export const homeLogos = [
  {
    partner_logo: {
      url: 'https://res.cloudinary.com/cryptr/image/upload/v1646148112/Cryptr%20www/Landing%20pages/Partner%20logos/sarbacane_logo_odklrk_x5qhmw.svg',
      website_link: 'https://sarbacane.com/',
      alt: 'Sarbacane',
    },
  },
  {
    partner_logo: {
      url: 'https://res.cloudinary.com/cryptr/image/upload/v1656511182/Cryptr%20www/Landing%20pages/Partner%20logos/holivia_logo_gcma1x.svg',
      website_link: 'https://www.holivia.fr/',
      alt: 'Holivia',
    },
  },
  {
    partner_logo: {
      url: 'https://res.cloudinary.com/cryptr/image/upload/v1670584601/Cryptr%20www/Landing%20pages/Partner%20logos/natif_logo_cieee8.svg',
      website_link: 'https://www.natif.io/',
      alt: 'natif.',
    },
  },
  {
    partner_logo: {
      url: 'https://res.cloudinary.com/cryptr/image/upload/v1656331787/Cryptr%20www/Landing%20pages/Partner%20logos/merci_yanis_logo_wwocvu.svg',
      website_link: 'https://www.merciyanis.com/',
      alt: 'MerciYanis',
    },
  },
  {
    partner_logo: {
      url: 'https://res.cloudinary.com/cryptr/image/upload/v1656331787/Cryptr%20www/Landing%20pages/Partner%20logos/talentview_logo_alwrew.svg',
      website_link: 'https://www.talentview.fr/',
      alt: 'TalentView',
    },
  },
]

export const partnerLogos = [
  {
    partner_logo: {
      url: 'https://res.cloudinary.com/cryptr/image/upload/v1646148112/Cryptr%20www/Landing%20pages/Partner%20logos/sarbacane_logo_odklrk_x5qhmw.svg',
      website_link: 'https://sarbacane.com/',
      alt: 'Sarbacane',
    },
  },
  {
    partner_logo: {
      url: 'https://res.cloudinary.com/cryptr/image/upload/v1646148112/Cryptr%20www/Landing%20pages/Partner%20logos/tradin_yjg3dk_bo0yzp.svg',
      website_link: 'https://www.tradeinsur.com/',
      alt: 'TradeIn',
    },
  },
  {
    partner_logo: {
      url: 'https://res.cloudinary.com/cryptr/image/upload/v1646148112/Cryptr%20www/Landing%20pages/Partner%20logos/foxy_nerds_logo_fliitp_bzsz4y.svg',
      website_link: 'https://www.foxynerds.com/',
      alt: 'FoxyNerds',
    },
  },
  {
    partner_logo: {
      url: 'https://res.cloudinary.com/cryptr/image/upload/v1656331787/Cryptr%20www/Landing%20pages/Partner%20logos/merci_yanis_logo_wwocvu.svg',
      website_link: 'https://www.merciyanis.com/',
      alt: 'MerciYanis',
    },
  },
  {
    partner_logo: {
      url: 'https://res.cloudinary.com/cryptr/image/upload/v1656331787/Cryptr%20www/Landing%20pages/Partner%20logos/talentview_logo_alwrew.svg',
      website_link: 'https://www.talentview.fr/',
      alt: 'TalentView',
    },
  },
]
