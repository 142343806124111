import * as React from 'react'

function SvgPadlockCloseIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={31} height={31} viewBox="0 0 31 31" fill="none" {...props}>
      <path
        d="M21.492 15.122a2.5 2.5 0 012.5 2.5v7.5a2.5 2.5 0 01-2.5 2.5h-12.5a2.5 2.5 0 01-2.5-2.5v-7.5a2.5 2.5 0 012.5-2.5v-6.25a6.25 6.25 0 1112.5 0v6.25zm-6.25 8.75a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm3.75-8.75v-6.25a3.75 3.75 0 00-7.5 0v6.25h7.5z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgPadlockCloseIcon
