import React from 'react'
import { LogoItem, LogosList } from './style'

type SectionLogosBase = {
  items: any
  text?: any
}

const SectionLogosBase = ({ items }: any) => (
  <LogosList>
    {items.map(
      (
        logo: {
          partner_logo: {
            website_link: string | undefined
            url: string | undefined
            alt: string | undefined
          }
        },
        index: React.Key,
      ) => {
        return (
          <LogoItem key={index}>
            <a href={logo.partner_logo.website_link} target="_blank">
              <img
                width="100%"
                height="100%"
                src={logo.partner_logo.url}
                alt={logo.partner_logo.alt}
              />
            </a>
          </LogoItem>
        )
      },
    )}
  </LogosList>
)

export default SectionLogosBase
