import styled from '@emotion/styled'
import tw from 'twin.macro'

export const LogoItem = styled.div`
  ${tw`
    flex
    flex-wrap
    justify-center
    items-center
    md:mt-0
    md:w-10/12
  `}
  img {
    ${tw`
      w-auto
      h-auto
    `}
    max-height: 3rem;
    @media (min-width: 768px) {
      max-height: 2rem;
    }
    @media (min-width: 1024px) {
      max-height: 3rem;
    }
  }
`

export const LogosList = styled.div`
  ${tw`
    py-10
    md:py-12
    grid 
    grid-cols-1 
    md:grid-cols-5 
    space-y-12
    md:space-y-0
  `}
`

export const LogoText = styled.div`
  ${tw`
    flex
    flex-wrap
    text-xs
    text-center
    text-white
    uppercase
    pb-8
  `}
  span {
    ${tw`
      w-full
    `}
  }
`
